import { silentUnreachableError } from "utils/exceptions";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { getFieldInitialValue } from "./constructors";
import { getLastOrder } from "./utils";

export function reducer(
  state: State.State,
  action: Actions.Actions,
): State.State {
  switch (action.type) {
    case "Schema:Add": {
      const field = getFieldInitialValue(action.payload);
      const order = (1 + getLastOrder(state.payload.fields)) / 2;

      return State.draft({
        ...state.payload,
        fields: { ...state.payload.fields, [field.id]: { ...field, order } },
      });
    }
    case "Schema:SetConfig": {
      const field = state.payload.fields[action.payload.id];

      if (!field) return state;

      return State.draft({
        ...state.payload,
        fields: {
          ...state.payload.fields,
          [field.id]: { ...field, config: action.payload.value },
        },
      });
    }
    case "Schema:SetLabel": {
      const field = state.payload.fields[action.payload.id];

      if (!field) return state;

      return State.draft({
        ...state.payload,
        fields: {
          ...state.payload.fields,
          [field.id]: { ...field, label: action.payload.value },
        },
      });
    }
    case "Schema:SetDesc": {
      const field = state.payload.fields[action.payload.id];

      if (!field) return state;

      return State.draft({
        ...state.payload,
        fields: {
          ...state.payload.fields,
          [field.id]: { ...field, description: action.payload.value },
        },
      });
    }
    case "Schema:SetRequired": {
      const field = state.payload.fields[action.payload.id];

      if (!field) return state;

      return State.draft({
        ...state.payload,
        fields: {
          ...state.payload.fields,
          [field.id]: { ...field, required: action.payload.value },
        },
      });
    }
    case "Schema:SetUnique": {
      const field = state.payload.fields[action.payload.id];

      if (!field) return state;

      return State.draft({
        ...state.payload,
        fields: {
          ...state.payload.fields,
          [field.id]: { ...field, unique: action.payload.value },
        },
      });
    }
    case "Schema:Remove": {
      const field = state.payload.fields[action.payload];

      if (!field) return state;

      const { [field.id]: _, ...fields } = state.payload.fields;

      return State.draft({
        ...state.payload,
        fields,
      });
    }
    default: {
      silentUnreachableError(action);
      return state;
    }
  }
}
