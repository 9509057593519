import { Selector, useSelector } from "state-manager";
import * as Create from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Create";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { SchemaFields, SchemaFieldsProps } from "@Containers/SchemaFields";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { SkuInput } from "@Containers/Form/predefined/SkuInput";

export interface ContentProps {
  selector: Selector<Create.State>;
  dispatch: (a: Create.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const onSchemaChange: SchemaFieldsProps["onChange"] = (v) => {
    switch (v.type) {
      case "number":
        dispatch(
          Create.schemaFieldsState.actions.setNumberValue.create({
            id: v.id,
            value: v.value as number,
          }),
        );
        break;
      case "text":
        dispatch(
          Create.schemaFieldsState.actions.setTextValue.create({
            id: v.id,
            value: v.value as string,
          }),
        );
        break;
      default:
        silentUnreachableError(v.type);
    }
  };
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:InventoryItems:Create:Loading":
        case "Ready:DataManager:InventoryItems:Create:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:InventoryItems:Create:Ready":
        case "Ready:DataManager:InventoryItems:Create:Saving": {
          return {
            type: "ready",
            schemaSelector: flow(
              selector,
              (s) => s as Create.Ready,
              (s) => s.payload.schema.payload,
            ),
            skuSelector: flow(
              selector,
              (s) => s as Create.Ready,
              (s) => s.payload.sku,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <SkuInput
            value$={r.skuSelector}
            onChange={flow(Create.setSku, dispatch)}
          />
          <SchemaFields selector={r.schemaSelector} onChange={onSchemaChange} />
        </FormWrapper>
      );
  }
}
