import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";
import { extractFieldsFromSchema } from "./utils";

export function createState<P extends string>(p: P) {
  return {
    isState: State.isState(p),
    states: {
      init: {
        create: State.init(p),
        is: State.isInit(p),
      },
      edited: {
        create: State.edited(p),
        is: State.isEdited(p),
      },
      valid: {
        create: State.valid(p),
        is: State.isValid(p),
      },
    },
    isActions: Actions.isActions(p),
    actions: {
      setTextValue: {
        create: Actions.setTextValue(p),
        is: Actions.isSetTextValue(p),
      },
      setNumberValue: {
        create: Actions.setNumberValue(p),
        is: Actions.isSetNumberValue(p),
      },
      validate: {
        create: Actions.validate(p),
        is: Actions.isValidate(p),
      },
    },
    init: State.init(p),
    reducer: reducer(p),
    epic: epic(p),
    extractFieldsFromSchema: extractFieldsFromSchema(p),
  };
}
