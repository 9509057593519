import {
  FieldType,
  FieldValue,
  FieldValueError,
} from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as FormValue from "types/src/FormValue";
import {
  NumberField,
  NumberFieldProps,
} from "@Containers/SchemaFields/controls/NumberField";
import { silentUnreachableError, unreachableError } from "utils/exceptions";
import {
  TextField,
  TextFieldProps,
} from "@Containers/SchemaFields/controls/TextField";

export interface SchemaFieldProps<T extends FieldType> {
  field$: (s: RootState) => Field<T>;
  value$: (
    s: RootState,
  ) =>
    | FormValue.Value<
        FieldValueError<T>,
        FieldValue<T> | undefined,
        FieldValue<T> | undefined
      >
    | undefined;
  onChange: (p: { type: T; value: FieldValue<T> | undefined }) => void;
}

export function SchemaField<T extends FieldType>({
  field$,
  value$,
  onChange,
}: SchemaFieldProps<T>): ReactElement {
  const r = useSelector(
    flow(field$, (f) => {
      switch (f.type) {
        case "number": {
          const props: NumberFieldProps = {
            field$: field$ as NumberFieldProps["field$"],
            value$: value$ as NumberFieldProps["value$"],
            onChange: (v) =>
              onChange({
                type: "number" as T,
                value: v as FieldValue<T> | undefined,
              }),
          };

          return {
            type: "number",
            props,
          } as const;
        }
        case "text": {
          const props: TextFieldProps = {
            field$: field$ as TextFieldProps["field$"],
            value$: value$ as TextFieldProps["value$"],
            onChange: (v) =>
              onChange({
                type: "text" as T,
                value: v as FieldValue<T> | undefined,
              }),
          };

          return {
            type: "text",
            props,
          } as const;
        }
        default: {
          unreachableError(f);

          throw new Error(`Unhandled field type: ${f}`);
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "number": {
      const { props } = r;

      return <NumberField {...props} />;
    }
    case "text": {
      const { props } = r;

      return <TextField {...props} />;
    }
    default: {
      silentUnreachableError(r);

      return <></>;
    }
  }
}
