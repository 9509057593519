import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { PickingOrderFragmentFragment } from "../generated/graphql";
import { pickingOrderItemFragmentToPickingOrderItem } from "./PickingOrderItem";

export function pickingOrderFragmentToPickingOrder(
  p: PickingOrderFragmentFragment,
): PickingOrder {
  return {
    id: p.id as PickingOrderId,
    fields: (p.data ?? { fields: {} }).fields as PickingOrder["fields"],
    createdAt: toDate(p.createdAt),
    updatedAt: pipe(p.updatedAt, O.fromNullable, O.map(toDate), O.toUndefined),
    dataTypeId: p.dataTypeID as PickingOrder["dataTypeId"],
    customerId: p.customerID as PickingOrder["customerId"],
    items: p.orderitems?.map(pickingOrderItemFragmentToPickingOrderItem) ?? [],
  };
}
