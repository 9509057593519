import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { strictGuard } from "utils/strictGuard";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | SetSku
  | Submit
  | SaveError
  | SaveSuccess
  | SchemaFieldsActions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (schemaFieldsState.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Edit:LoadFail":
    case "Ready:DataManager:InventoryItems:Edit:LoadSuccess":
    case "Ready:DataManager:InventoryItems:Edit:SetSku":
    case "Ready:DataManager:InventoryItems:Edit:SaveError":
    case "Ready:DataManager:InventoryItems:Edit:SaveSuccess":
    case "Ready:DataManager:InventoryItems:Edit:Submit":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:InventoryItems:Edit:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:InventoryItems:Edit:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:InventoryItems:Edit:LoadSuccess";
  payload: {
    fields: Record<FieldId, Field<FieldType>>;
    item: InventoryItem;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:InventoryItems:Edit:LoadSuccess",
  payload,
});
// endregion

// region SetSku
export interface SetSku {
  type: "Ready:DataManager:InventoryItems:Edit:SetSku";
  payload: string;
}

export const setSku = (payload: SetSku["payload"]): SetSku => ({
  type: "Ready:DataManager:InventoryItems:Edit:SetSku",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:InventoryItems:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:InventoryItems:Edit:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:InventoryItems:Edit:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:InventoryItems:Edit:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:InventoryItems:Edit:SaveSuccess";
  payload: InventoryItem;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:InventoryItems:Edit:SaveSuccess",
  payload,
});
// endregion
