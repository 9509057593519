import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as Obj from "utils/object";
import * as FormValue from "types/src/FormValue";
import { FieldId } from "types/src/DataType/FieldType";
import { pipe } from "fp-ts/function";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";
import {
  createCustomerSearchState,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "./utils";

export const reducer = <P extends string>(p: P) => {
  const customerSearch = createCustomerSearchState(p);
  const schemaFields = createSchemaFieldsState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const isLoadFail = Actions.isLoadFail(p);
  const isLoadSuccess = Actions.isLoadSuccess(p);
  const isSubmit = Actions.isSubmit(p);
  const isSaveFail = Actions.isSaveFail(p);
  const isSaveSuccess = Actions.isSaveSuccess(p);
  const isLoadError = State.isLoadError(p);
  const isLoading = State.isLoading(p);
  const isReady = State.isReady(p);
  const loadError = State.loadError(p);
  const ready = State.ready(p);
  const saving = State.saving(p);
  const isSaving = State.isSaving(p);

  return (
    s: State.State<P>,
    a: Actions.Actions<P>,
  ): E.Either<Exits.Exits<P>, State.State<P>> => {
    if (isLoadFail(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        loadError({
          ...s.payload,
          error: a.payload,
        }),
      );
    }

    if (isLoadSuccess(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: pickingOrderItemsState.init({
            items: {},
            dataTypes: a.payload.dataTypes,
          }),
          fields: schemaFields.init({
            fields: a.payload.dataType.schema.fields,
            values: Obj.keys(a.payload.dataType.schema.fields).reduce(
              (acc, v) => {
                acc[v] = FormValue.initial(undefined);

                return acc;
              },
              {} as Record<FieldId, FormValue.Initial<undefined>>,
            ),
          }),
          customer: customerSearch.init(),
          dataTypes: a.payload.dataTypes,
        }),
      );
    }

    if (isSubmit(a)) {
      if (!isReady(s)) return E.right(s);

      const items = pipe(
        pickingOrderItemsState.reducer(
          s.payload.items,
          pickingOrderItemsState.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.items),
      );
      const customer = s.payload.customer;
      const fields = schemaFields.reducer(
        s.payload.fields,
        schemaFields.actions.validate.create(),
      );

      if (
        customerSearch.states.selected.is(customer) &&
        schemaFields.states.valid.is(fields) &&
        pickingOrderItemsState.states.valid.is(items)
      ) {
        return E.right(
          saving({
            ...s.payload,
            customer,
            fields,
            items,
          }),
        );
      }

      return E.right(ready({ ...s.payload, fields, items, customer }));
    }

    if (isSaveFail(a)) {
      if (!isSaving(s)) return E.right(s);

      return E.right(ready(s.payload));
    }

    if (isSaveSuccess(a)) {
      if (!isSaving(s)) return E.right(s);

      return E.left(Exits.list(p)(a.payload));
    }

    if (customerSearch.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          customer: customerSearch.reducer(s.payload.customer, a),
        }),
      );
    }

    if (schemaFields.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          fields: schemaFields.reducer(s.payload.fields, a),
        }),
      );
    }

    if (pickingOrderItemsState.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: pipe(
            pickingOrderItemsState.reducer(s.payload.items, a),
            E.getOrElse(() => s.payload.items),
          ),
        }),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};
