import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { isOneOf } from "utils/isOneOf";
import { v4 as uuidv4 } from "uuid";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import {
  CustomerSearchState,
  CustomerSearchStatesMap,
  PickingOrderItemsState,
  PickingOrderItemsStatesMap,
  SchemaFieldsState,
  SchemaFieldsStatesMap,
} from "../utils";

export type State<P extends string> =
  | Loading<P>
  | LoadError<P>
  | Ready<P>
  | Saving<P>;

export const isState = <P extends string>(p: P) =>
  strictGuard(isOneOf([isLoading(p), isLoadError(p), isReady(p), isSaving(p)]));

// region Loading
export interface LoadingPayload {
  dataTypeId: DataTypeId;
}

export interface Loading<P extends string> {
  type: `${P}:Loading`;
  payload: LoadingPayload;
}

export const loading =
  <P extends string>(p: P) =>
  (payload: Loading<P>["payload"]): Loading<P> => ({
    type: `${p}:Loading`,
    payload,
  });

export const isLoading =
  <P extends string>(p: P) =>
  (s: State<P>): s is Loading<P> =>
    s.type === `${p}:Loading`;
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  error: DsError;
}

export interface LoadError<P extends string> {
  type: `${P}:LoadError`;
  payload: LoadErrorPayload;
}

export const loadError =
  <P extends string>(p: P) =>
  (payload: LoadError<P>["payload"]): LoadError<P> => ({
    type: `${p}:LoadError`,
    payload,
  });

export const isLoadError =
  <P extends string>(p: P) =>
  (s: State<P>): s is LoadError<P> =>
    s.type === `${p}:LoadError`;
// endregion

// region Ready
export interface ReadyPayload<P extends string> extends LoadingPayload {
  customer: CustomerSearchState<P>;
  dataTypes: NoEmptyArr<{
    title: string;
    id: DataTypeId;
    schema: DataTypeSchema;
  }>;
  fields: SchemaFieldsState<P>;
  items: PickingOrderItemsState<P>;
}

export interface Ready<P extends string> {
  type: `${P}:Ready`;
  payload: ReadyPayload<P>;
}

export const ready =
  <P extends string>(p: P) =>
  (payload: Ready<P>["payload"]): Ready<P> => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  <P extends string>(p: P) =>
  (s: State<P>): s is Ready<P> =>
    s.type === `${p}:Ready`;
// endregion

// region Saving
export interface SavingPayload<P extends string> extends ReadyPayload<P> {
  customer: CustomerSearchStatesMap<P>["selected"];
  fields: SchemaFieldsStatesMap<P>["valid"];
  items: PickingOrderItemsStatesMap<P>["valid"];
}

export interface Saving<P extends string> {
  type: `${P}:Saving`;
  payload: SavingPayload<P>;
}

export const saving =
  <P extends string>(p: P) =>
  (payload: Saving<P>["payload"]): Saving<P> => ({
    type: `${p}:Saving`,
    payload,
  });

export const isSaving =
  <P extends string>(p: P) =>
  (s: State<P>): s is Saving<P> =>
    s.type === `${p}:Saving`;
// endregion

// region ItemId
declare const _itemId: unique symbol;

export type ItemId = string & { [_itemId]: "ItemId" };

export const createItemId = (): ItemId => uuidv4() as ItemId;
// endregion
