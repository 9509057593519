import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  NEVER,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import * as E from "fp-ts/Either";
import { Client, DsError, notFoundError } from "ds";
import { getItemMovement, updateItemMovement } from "ds/ItemMovements";
import { getDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { getInventoryItems } from "ds/InventoryItems";
import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { getRepositories } from "ds/Repositories";
import { Repository } from "types/src/Repositories/Repository";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import { extractFieldsFromSchema } from "../../../../../../../../generic-states/SchemaFields/utils";
import { Epic } from "../../../../../../../../types/RootEpic";
import { SearchItem, SearchItemValid } from "../../types/SearchItem";
import { dsErrorNotification } from "../../../../../../../Notifications/epic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.id),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) => {
      return from(getItemMovement(client, id)).pipe(
        switchMap(
          flow(
            E.map((i) => {
              return forkJoin({
                fields: from(getDataType(client, i.dataTypeId)).pipe(
                  map(E.chain(E.fromNullable<DsError>(notFoundError()))),
                  map(
                    E.filterOrElse(
                      (v) => v.entity === "movement",
                      (): DsError => notFoundError(),
                    ),
                  ),
                  map(E.map((v) => v.schema.fields)),
                ),
                inventoryItems: from(getInventoryItems(client, {})).pipe(
                  map(E.map((v) => v.items)),
                  map(E.getOrElse((): InventoryItem[] => [])),
                  catchError(() => of<InventoryItem[]>([])),
                ),
                repositories: from(getRepositories(client, {})).pipe(
                  map(E.map((v) => v.items)),
                  map(E.getOrElse((): Repository[] => [])),
                  catchError(() => of<Repository[]>([])),
                ),
              }).pipe(
                map((r) => {
                  if (E.isLeft(r.fields)) return Actions.loadFail();

                  return Actions.loadSuccess({
                    dataTypeId: i.dataTypeId,
                    itemMovement: i,
                    fields: r.fields.right,
                    repositories: r.repositories,
                    inventoryItems: r.inventoryItems,
                  });
                }),
              );
            }),
            E.getOrElseW(() => of(Actions.loadFail())),
          ),
        ),
      );
    }),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateItemMovement(client, {
          id: s.id,
          dataTypeId: s.dataTypeId,
          fields: extractFieldsFromSchema(
            "Ready:DataManager:ItemMovements:Edit",
          )(s.schema.payload.values),
        }),
      ).pipe(
        dsErrorNotification(
          flow(E.map(Actions.saveSuccess), E.getOrElseW(Actions.saveError)),
        ),
      ),
    ),
  );

  function searchT<T>(
    get: (
      client: Client,
      s: { where: { search: string | undefined } },
    ) => Promise<E.Either<DsError, { items: T[] }>>,
    fa: (t: T[]) => Actions.Actions,
  ) {
    return (
      v$: Observable<
        FormValue.AsyncValue<unknown, SearchItemValid<T>, SearchItem<T>>
      >,
    ) =>
      v$.pipe(
        debounceTime(500),
        switchMap((v) => {
          return FormValue.isVerifying(v)
            ? of(v).pipe(
                map((v) => O.toUndefined(v.value.search)),
                distinctUntilChanged(),
                withLatestFrom(pyckAdminClient$),
                switchMap(([search, client]) => {
                  return from(get(client, { where: { search } })).pipe(
                    map(E.map((v) => v.items)),
                    map(E.getOrElse((): T[] => [])),
                    catchError(() => of<T[]>([])),
                    map(fa),
                  );
                }),
              )
            : NEVER;
        }),
      );
  }

  const searchItem$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.item),
    searchT(getInventoryItems, Actions.inventoryItemsSearchResult),
  );

  const searchReposFrom$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.from),
    searchT(getRepositories, Actions.repositoriesFromSearchResult),
  );

  const searchReposTo$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.to),
    searchT(getRepositories, Actions.repositoriesFromSearchResult),
  );

  return merge(
    loading$,
    update$,
    fieldsSchema$,
    searchItem$,
    searchReposFrom$,
    searchReposTo$,
  );
};
