import { gql } from "@apollo/client";
import { FieldId, FieldType, FieldValue } from "types/src/DataType/FieldType";
import { DataTypeId } from "types/src/DataType/DataType";
import { print } from "graphql/language";
import {
  RepoWithMeta,
  Repository,
  RepositoryId,
  RequiredFields,
} from "types/src/Repositories/Repository";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { repositoryFragment } from "../../fragments/Repository";
import {
  CreateRepositoryMutation,
  CreateRepositoryMutationVariables,
  UpdateRepositoryMutation,
  UpdateRepositoryMutationVariables,
} from "../../generated/graphql";
import { Client, notFoundError } from "../../index";
import {
  repositoryFragmentToRepository,
  repositoryTypeToApiResponseType,
} from "../../transformers/Repositories";
import { QueryResponse } from "../../type/QueryResponse";

export const createRepository = (
  client: Client,
  vars: {
    parent?: RepositoryId;
    dataTypeId: DataTypeId;
    name: NoEmptyString;
    type: "static" | "dynamic";
    fields: Record<FieldId, FieldValue<FieldType>>;
    isVirtual: boolean;
  },
): Promise<QueryResponse<Repository>> => {
  const mutation = gql`
    ${repositoryFragment}
    mutation CreateRepository($input: CreateRepositoryInput!) {
      createInventoryRepository(input: $input) {
        inventoryRepository {
          ...RepositoryFragment
        }
      }
    }
  `;

  return client
    .mutate<CreateRepositoryMutation, CreateRepositoryMutationVariables>({
      mutation,
      variables: {
        input: {
          name: vars.name,
          type: repositoryTypeToApiResponseType(vars.type),
          virtualRepo: vars.isVirtual,
          data: {
            fields: vars.fields,
          },
          dataTypeID: vars.dataTypeId,
          parentID: vars.parent,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createInventoryRepository?.inventoryRepository),
        E.filterOrElseW(isT, notFoundError),
        E.map(repositoryFragmentToRepository),
      ),
    );
};

type CreateReposInput = Record<
  string,
  {
    data: {
      fields: Record<string, unknown>;
    };
    dataTypeID: DataTypeId;
  }
>;
export const createRepositories = async (
  client: Client,
  vars: {
    items: {
      name: NoEmptyString;
      parentID?: RepositoryId;
      type: "static" | "dynamic";
      data: Record<string, unknown>;
    }[];
    dataTypeId: DataTypeId;
  },
): Promise<QueryResponse<RepoWithMeta[]>> => {
  const template = `
    data_repository_id: createInventoryRepository(input: [REPOSITORY_INPUT]) {
      inventoryRepository {
        ...RepositoryFragment
      }
    }
  `;

  const fakeMutations: string[] = [];
  const fakeTypes: string[] = [];
  const fakeVariables: CreateReposInput = {};
  vars.items.forEach((v, i) => {
    const name = `data_repository_input_${i}`;

    fakeMutations.push(
      template
        .replace("[REPOSITORY_INPUT]", `$${name}`)
        .replace("data_repository_id", `data_repository_${i}`),
    );

    fakeVariables[name] = {
      ...v,
      dataTypeID: vars.dataTypeId,
      data: { fields: v.data },
    };

    fakeTypes.push(`$${name}: CreateRepositoryInput!`);
  });

  const query = `
    mutation CreateRepositories(${fakeTypes.join(", ")}) {
        ${fakeMutations.join("\n")}
    }
  `;

  return client
    .mutate<
      Record<string, CreateRepositoryMutation["createInventoryRepository"]>,
      CreateReposInput
    >({
      mutation: gql(`${print(repositoryFragment)}${query}`),
      variables: fakeVariables,
    })
    .then(flow(E.map(Object.values)));
};

interface UpdateReposInput {
  [K: `data_repository_input_${number}`]: {
    data: {
      fields: RepoWithMeta["data"] | undefined;
    };
    dataTypeID: DataTypeId;
  };
  [T: `data_repository_id_${number}`]: RepoWithMeta["id"];
}

export const updateRepositories = async (
  client: Client,
  vars: {
    items: RequiredFields<Partial<RepoWithMeta>, "id">[];
    dataTypeId: DataTypeId;
  },
): Promise<QueryResponse<Record<string, RepoWithMeta>>> => {
  const template = `
    data_repository_id: updateInventoryRepository(id: [REPOSITORY_ID], input: [REPOSITORY_INPUT]) {
      inventoryRepository {
          ...RepositoryFragment
      }
    }
  `;

  const fakeMutations: string[] = [];
  const fakeTypes: string[] = [];
  const fakeVariables: UpdateReposInput = {};
  const fakeIdDataBinding: Record<string, RepoWithMeta["id"]> = {};
  vars.items.forEach((props, i) => {
    const { id, ...v } = props;
    const name = `data_repository_input_${i}` as const;
    const idName = `data_repository_id_${i}` as const;

    fakeIdDataBinding[`data_repository_${i}`] = id;

    fakeMutations.push(
      template
        .replace("[REPOSITORY_INPUT]", `$${name}`)
        .replace("[REPOSITORY_ID]", `$${idName}`)
        .replace("data_repository_id", `data_repository_${i}`),
    );

    fakeVariables[name] = {
      ...v,
      dataTypeID: vars.dataTypeId,
      data: { fields: v.data },
    };

    fakeVariables[idName] = id;

    fakeTypes.push(`$${idName}: ID!`);
    fakeTypes.push(`$${name}: UpdateRepositoryInput!`);
  });

  const query = `
    mutation UpdateRepositories(${fakeTypes.join(", ")}) {
        ${fakeMutations.join("\n")}
    }
  `;

  return client
    .mutate<
      Record<string, UpdateRepositoryMutation["updateInventoryRepository"]>,
      UpdateReposInput
    >({
      mutation: gql(`${print(repositoryFragment)}${query}`),
      variables: fakeVariables,
    })
    .then(
      flow(
        E.map((v) => {
          const data = Object.fromEntries(
            Object.entries(v)
              .map(([k, value]) => {
                const oldId = fakeIdDataBinding[k];
                if (!oldId || !value?.inventoryRepository) return undefined;

                return [
                  oldId,
                  repositoryFragmentToRepository(value.inventoryRepository),
                ];
              })
              .filter(isT),
          );

          return data;
        }),
      ),
    );
};

export const updateRepository = (
  client: Client,
  vars: {
    id: RepositoryId;
    parent?: RepositoryId;
    name: NoEmptyString;
    type: "static" | "dynamic";
    dataTypeId: DataTypeId;
    fields: Record<FieldId, FieldValue<FieldType>>;
  },
): Promise<QueryResponse<Repository>> => {
  const mutation = gql`
    ${repositoryFragment}
    mutation UpdateRepository($id: ID!, $input: UpdateRepositoryInput!) {
      updateInventoryRepository(id: $id, input: $input) {
        inventoryRepository {
          ...RepositoryFragment
        }
      }
    }
  `;

  return client
    .mutate<UpdateRepositoryMutation, UpdateRepositoryMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          data: {
            fields: vars.fields,
          },
          name: vars.name,
          type: repositoryTypeToApiResponseType(vars.type),
          parentID: vars.parent,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.updateInventoryRepository?.inventoryRepository),
        E.filterOrElseW(isT, notFoundError),
        E.map(repositoryFragmentToRepository),
      ),
    );
};

export const deleteRepositories = (
  client: Client,
  ids: RepositoryId[],
): Promise<QueryResponse<void>> => {
  const template = `
    data_repository_id: deleteInventoryRepository(id: "[REPOSITORY_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[REPOSITORY_ID]", id)
      .replace("data_repository_id", `data_repository_${i}`),
  );
  const query = `
      mutation DeleteRepositories {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};
