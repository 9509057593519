import { silentUnreachableError } from "utils/exceptions";
import { FieldId } from "types/src/DataType/FieldType";
import { strictGuard } from "utils/strictGuard";

// region Actions
export type Actions<P extends string> =
  | SetTextValue<P>
  | SetNumberValue<P>
  | Validate<P>;

export const isActions = <P extends string>(p: P) =>
  strictGuard((a: Actions<P>): a is Actions<P> => {
    if (isSetTextValue(p)(a) || isSetNumberValue(p)(a) || isValidate(p)(a)) {
      return true;
    }

    silentUnreachableError(a);
    return false;
  });
// endregion

// region SetTextValue
export interface SetTextValue<P extends string> {
  type: `${P}:ItemSearch:SetTextValue`;
  payload: {
    id: FieldId;
    value: string;
  };
}

export const setTextValue =
  <P extends string>(p: P) =>
  (payload: SetTextValue<P>["payload"]): SetTextValue<P> => ({
    type: `${p}:ItemSearch:SetTextValue`,
    payload,
  });

export const isSetTextValue =
  <P extends string>(p: P) =>
  (v: Actions<P>): v is SetTextValue<P> =>
    v.type === `${p}:ItemSearch:SetTextValue`;
// endregion

// region SetNumberValue
export interface SetNumberValue<P extends string> {
  type: `${P}:SetNumberValue`;
  payload: {
    id: FieldId;
    value: number;
  };
}

export const setNumberValue =
  <P extends string>(p: P) =>
  (payload: SetNumberValue<P>["payload"]): SetNumberValue<P> => ({
    type: `${p}:SetNumberValue`,
    payload,
  });

export const isSetNumberValue =
  <P extends string>(p: P) =>
  (v: Actions<P>): v is SetNumberValue<P> =>
    v.type === `${p}:SetNumberValue`;
// endregion

// region Validate
export interface Validate<P extends string> {
  type: `${P}:Validate`;
}

export const validate =
  <P extends string>(p: P) =>
  (): Validate<P> => ({
    type: `${p}:Validate`,
  });

export const isValidate =
  <P extends string>(p: P) =>
  (v: Actions<P>): v is Validate<P> =>
    v.type === `${p}:Validate`;
// endregion
