import { silentUnreachableError } from "utils/exceptions";
import { keys } from "utils/object";
import { FieldId } from "types/src/DataType/FieldType";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { isNoEmptyString, NoEmptyString } from "types/src/NoEmptyString";
import * as Edit from "../Edit/types/State";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Edit.Ready {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        schema: schemaFieldsState.reducer(s.payload.schema, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:ItemMovements:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError({
          id: s.payload.id,
        });
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          dataTypeId: a.payload.dataTypeId,
          item: FormValue.valid({
            items: a.payload.inventoryItems,
            search: O.none,
            selected: a.payload.itemMovement.item,
          }),
          from: FormValue.valid({
            items: a.payload.repositories,
            search: O.none,
            selected: a.payload.itemMovement.from,
          }),
          to: FormValue.valid({
            items: a.payload.repositories,
            search: O.none,
            selected: a.payload.itemMovement.to,
          }),
          quantity: FormValue.valid(a.payload.itemMovement.quantity),
          handler: FormValue.valid(a.payload.itemMovement.handler),
          schema: schemaFieldsState.states.init.create({
            fields: a.payload.fields,
            values: keys(a.payload.fields).reduce(
              (acc, key) => {
                acc[key] = FormValue.initial(undefined);
                return acc;
              },
              {} as Record<FieldId, FormValue.Initial<undefined>>,
            ),
          }),
          isExecuted: a.payload.itemMovement.executed,
          id: s.payload.id,
        });
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:Edit:Submit": {
      if (State.isEditable(s)) {
        const validated = schemaFieldsState.reducer(
          s.payload.schema,
          schemaFieldsState.actions.validate.create(),
        );

        if (
          schemaFieldsState.states.valid.is(validated) &&
          FormValue.isValid(s.payload.item) &&
          FormValue.isValid(s.payload.from) &&
          FormValue.isValid(s.payload.to) &&
          FormValue.isValid(s.payload.quantity) &&
          FormValue.isValid(s.payload.handler)
        ) {
          return State.saving({
            dataTypeId: s.payload.dataTypeId,
            schema: validated,
            item: s.payload.item,
            from: s.payload.from,
            to: s.payload.to,
            quantity: s.payload.quantity,
            handler: s.payload.handler,
            isExecuted: s.payload.isExecuted,
            id: s.payload.id,
          });
        } else {
          return State.ready({
            id: s.payload.id,
            dataTypeId: s.payload.dataTypeId,
            item: FormValue.isValid(s.payload.item)
              ? s.payload.item
              : FormValue.invalid("required", s.payload.item.value),
            from: FormValue.isValid(s.payload.from)
              ? s.payload.from
              : FormValue.invalid("required", s.payload.from.value),
            to: FormValue.isValid(s.payload.to)
              ? s.payload.to
              : FormValue.invalid("required", s.payload.to.value),
            quantity: FormValue.isValid(s.payload.quantity)
              ? s.payload.quantity
              : FormValue.invalid("required", s.payload.quantity.value),
            handler: FormValue.isValid(s.payload.handler)
              ? s.payload.handler
              : FormValue.invalid("required", s.payload.handler.value),
            schema: validated,
            isExecuted: s.payload.isExecuted,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:Edit:SaveError": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:ItemMovements:Edit:InventoryItemsSearchResult": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          pipe(
            s.payload.item,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    item: FormValue.initial({
                      search: v.value.search,
                      items: a.payload,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesFromSearchResult": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          pipe(
            s.payload.item,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    from: FormValue.initial({
                      search: v.value.search,
                      items: a.payload,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesToSearchResult": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          pipe(
            s.payload.item,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    to: FormValue.initial({
                      search: v.value.search,
                      items: a.payload,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetItem": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) => {
          return pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(
                s.payload.item.value.items.find((i) => i.id === id),
              ),
            ),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    item: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.item.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetFrom": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) => {
          return pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(
                s.payload.from.value.items.find((i) => i.id === id),
              ),
            ),
            O.filter((v) => {
              const to = s.payload.to;

              return !FormValue.isValid(to) || to.value.selected.id !== v.id;
            }),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    from: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.from.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetTo": {
      return pipe(
        s,
        O.some,
        O.filter(State.isEditable),

        O.map((s) => {
          return pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(s.payload.to.value.items.find((i) => i.id === id)),
            ),
            O.filter((v) => {
              const from = s.payload.from;

              return (
                !FormValue.isValid(from) || from.value.selected.id !== v.id
              );
            }),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    to: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.to.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchItem": {
      return pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              item: FormValue.verifying({
                search: O.fromPredicate(isNoEmptyString)(a.payload),
                items: s.payload.item.value.items,
              }),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchFrom": {
      return pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              from: FormValue.verifying({
                search: s.payload.from.value.search,
                items: s.payload.from.value.items,
              }),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchTo": {
      return pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              to: FormValue.verifying({
                search: s.payload.to.value.search,
                items: s.payload.to.value.items,
              }),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetQuantity": {
      return pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                quantity: pipe(
                  a.payload,
                  O.map(FormValue.valid),
                  O.getOrElse<
                    FormValue.SubmittedValue<
                      FormValue.Value<"required", number, number | undefined>
                    >
                  >(() => FormValue.invalid("required" as const, undefined)),
                ),
              },
            }) satisfies typeof s,
        ),
        O.getOrElse(() => s),
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetHandler": {
      return pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                handler: pipe(
                  a.payload,
                  O.fromPredicate(isNoEmptyString),
                  O.map(FormValue.valid),
                  O.getOrElse<
                    FormValue.SubmittedValue<
                      FormValue.Value<"required", NoEmptyString, string>
                    >
                  >(() => FormValue.invalid("required", a.payload)),
                ),
              },
            }) satisfies typeof s,
        ),
        O.getOrElse(() => s),
      );
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
