import * as Rx from "rxjs";
import { Client, DsError, notFoundError } from "ds";
import { isOneOf } from "utils/isOneOf";
import { getCustomers } from "ds/Customers";
import * as E from "fp-ts/Either";
import { distinctUntilKeyChanged } from "rxjs";
import { getDataTypes } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { createPickingOrder } from "ds/PickingOrder";
import { PickingOrderItemCreateWithoutOrder } from "types/src/PickingOrder/PickingOrderItem";
import * as Obj from "utils/object";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { DataTypeId } from "types/src/DataType/DataType";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import {
  createCustomerSearchState,
  createPickingOrderItemsState,
  createSchemaFieldsState,
} from "./utils";

export const epic = <P extends string>(
  p: P,
): Epic<
  Actions.Actions<P>,
  State.State<P>,
  { pyckAdminClient$: Rx.Observable<Client> }
> => {
  const isLoading = State.isLoading(p);
  const isReady = State.isReady(p);
  const isSaving = State.isSaving(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const schemaFieldsState = createSchemaFieldsState(p);
  const customerSearchState = createCustomerSearchState(p);
  const loadSuccess = Actions.loadSuccess(p);
  const saveFail = Actions.saveFail(p);
  const saveSuccess = Actions.saveSuccess(p);
  const loadFail = Actions.loadFail(p);

  return (state$, { pyckAdminClient$ }) => {
    const pickingOrderItems$ = pickingOrderItemsState.epic(
      state$.pipe(
        Rx.filter(isOneOf([isReady, isSaving])),
        Rx.map((s) => s.payload.items),
      ),
      {},
    );

    const loading$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) =>
        state$.pipe(
          distinctUntilKeyChanged("type"),
          Rx.filter(isLoading),
          Rx.switchMap((s) => {
            return Rx.from(
              getDataTypes(client, { where: { entity: ["order"] } }),
            ).pipe(
              Rx.map(
                flow((v) => {
                  if (E.isLeft(v) || !v.right.items.length)
                    return E.left(notFoundError());

                  const dataTypes = v.right.items.map((i) => ({
                    title: i.name,
                    id: i.id,
                    schema: i.schema,
                    isDefault: i.default,
                  })) as unknown as NoEmptyArr<{
                    title: string;
                    id: DataTypeId;
                    schema: DataTypeSchema;
                    isDefault: boolean;
                  }>;
                  const dataType = dataTypes.find(
                    (v) => v.id === s.payload.dataTypeId,
                  );

                  if (!dataType) return E.left(notFoundError());

                  return E.right(loadSuccess({ dataType, dataTypes }));
                }, E.getOrElse<DsError, Actions.Actions<P>>(loadFail)),
              ),
            );
          }),
        ),
      ),
    );

    const fieldsSchema$ = schemaFieldsState.epic(
      state$.pipe(
        Rx.filter(isOneOf([isReady, isSaving])),
        Rx.map((s) => s.payload.fields),
      ),
      pyckAdminClient$,
    );

    const customerSearch$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) =>
        customerSearchState.epic(
          state$.pipe(
            Rx.filter(isOneOf([isReady, isSaving])),
            Rx.map((s) => s.payload.customer),
          ),
          {
            get: (q) => {
              return getCustomers(client, {
                where: { search: q },
              }).then(
                E.map((r) => r.items.map((v) => ({ title: v.id, id: v.id }))),
              );
            },
          },
        ),
      ),
    );

    const save$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) =>
        state$.pipe(
          distinctUntilKeyChanged("type"),
          Rx.filter(isSaving),
          Rx.switchMap((s) => {
            return Rx.from(
              createPickingOrder(client, {
                dataTypeId: s.payload.dataTypeId,
                fields: schemaFieldsState.extractFieldsFromSchema(
                  s.payload.fields.payload.values,
                ),
                customerId: s.payload.customer.payload.item.id,
                orderItems: Obj.values(s.payload.items.payload.items).map(
                  (v): PickingOrderItemCreateWithoutOrder => ({
                    fields: Obj.map(
                      (v) => v.value,
                      v.payload.fields.payload.values,
                    ),
                    dataTypeId: v.payload.dataTypeId,
                    sku: v.payload.sku.value,
                    quantity: v.payload.quantity.value,
                  }),
                ),
              }),
            ).pipe(
              Rx.map(
                flow(
                  E.map(saveSuccess),
                  E.getOrElse<DsError, Actions.Actions<P>>(saveFail),
                ),
              ),
            );
          }),
        ),
      ),
    );

    return Rx.merge(
      fieldsSchema$,
      customerSearch$,
      loading$,
      save$,
      pickingOrderItems$,
    );
  };
};
