import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { strictGuard } from "utils/strictGuard";
import { EditedValue, InitialValue, ValidValue } from "./Value";

// region State
export type State<P extends string> = Init<P> | Edited<P> | Valid<P>;

export const isState = <P extends string>(p: P) =>
  strictGuard((s: State<P>): s is State<P> => {
    if (isInit(p)(s) || isEdited(p)(s) || isValid(p)(s)) {
      return true;
    }

    silentUnreachableError(s);
    return false;
  });
// endregion

// region Init
export interface InitPayload {
  fields: Record<FieldId, Field<FieldType>>;
  values: InitialValue;
}

export interface Init<P extends string> {
  type: `${P}:Init`;
  payload: InitPayload;
}

export const init =
  <P extends string>(p: P) =>
  (payload: Init<P>["payload"]): Init<P> => ({
    type: `${p}:Init`,
    payload,
  });

export const isInit =
  <P extends string>(p: P) =>
  (a: State<P>): a is Init<P> =>
    a.type === `${p}:Init`;
// endregion

// region Edited
export interface EditedPayload extends Omit<InitPayload, "values"> {
  values: EditedValue;
}

export interface Edited<P extends string> {
  type: `${P}:Edited`;
  payload: EditedPayload;
}

export const edited =
  <P extends string>(p: P) =>
  (payload: Edited<P>["payload"]): Edited<P> => ({
    type: `${p}:Edited`,
    payload,
  });

export const isEdited =
  <P extends string>(p: P) =>
  (a: State<P>): a is Edited<P> =>
    a.type === `${p}:Edited`;
// endregion

// region Valid
export interface ValidPayload extends EditedPayload {
  values: ValidValue;
}

export interface Valid<P extends string> {
  type: `${P}:Valid`;
  payload: ValidPayload;
}

export const valid =
  <P extends string>(p: P) =>
  (payload: Valid<P>["payload"]): Valid<P> => ({
    type: `${p}:Valid`,
    payload,
  });

export const isValid =
  <P extends string>(p: P) =>
  (a: State<P>): a is Valid<P> =>
    a.type === `${p}:Valid`;
// endregion
