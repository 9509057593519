import { silentUnreachableError } from "utils/exceptions";
import { keys } from "utils/object";
import { FieldId, FieldType, FieldValue } from "types/src/DataType/FieldType";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import * as O from "fp-ts/Option";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { parentSearchState, schemaFieldsState } from "./utils";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        schema: schemaFieldsState.reducer(s.payload.schema, a),
      });
    }

    return s;
  }

  if (parentSearchState.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        parent: parentSearchState.reducer(s.payload.parent, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Repositories:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        const repositories = a.payload.repositories.filter(
          (v) => v.id !== a.payload.item.id,
        );

        return State.ready({
          id: a.payload.item.id,
          dataTypeId: a.payload.item.dataTypeId,
          name: FormValue.valid(a.payload.item.name),
          type: FormValue.valid(a.payload.item.type),
          isVirtual: a.payload.item.isVirtual,
          parent: a.payload.item.parentId
            ? parentSearchState.states.selected.create({
                query: O.none,
                items: repositories,
                item: repositories.find(
                  (r) => r.id === a.payload.item.parentId,
                ),
              })
            : parentSearchState.states.idle.create({
                query: O.none,
                items: repositories,
              }),
          schema: schemaFieldsState.states.init.create({
            fields: a.payload.fields,
            values: keys(a.payload.fields).reduce(
              (acc, key) => {
                acc[key] = FormValue.initial(a.payload.item.fields[key]);
                return acc;
              },
              {} as Record<
                FieldId,
                FormValue.Initial<FieldValue<FieldType> | undefined>
              >,
            ),
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:SetName": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return State.ready({
          ...s.payload,
          name: isNoEmptyString(a.payload)
            ? FormValue.valid(a.payload)
            : FormValue.invalid("required" as const, a.payload),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:SetType": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return State.ready({
          ...s.payload,
          type: FormValue.valid(a.payload),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:Submit": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        const validated = schemaFieldsState.reducer(
          s.payload.schema,
          schemaFieldsState.actions.validate.create(),
        );
        const name = isNoEmptyString(s.payload.name.value)
          ? FormValue.valid(s.payload.name.value)
          : FormValue.invalid("required" as const, s.payload.name.value);
        const type = s.payload.type.value
          ? FormValue.valid(s.payload.type.value)
          : FormValue.invalid("required" as const, s.payload.type.value);

        if (
          schemaFieldsState.states.valid.is(validated) &&
          FormValue.isValid(name) &&
          FormValue.isValid(type)
        ) {
          return State.saving({
            ...s.payload,
            name: name,
            type: type,
            schema: validated,
          });
        } else {
          return State.ready({
            ...s.payload,
            name: name,
            type: type,
            schema: validated,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:SaveError": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
