import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as Obj from "utils/object";
import * as FormValue from "types/src/FormValue";
import { pipe } from "fp-ts/function";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import {
  createCustomerSearchState,
  createPickingOrderItemsState,
  createSchemaFieldsState,
} from "./utils";
import * as Exits from "./types/Exits";

export const reducer = <P extends string>(p: P) => {
  const customerSearch = createCustomerSearchState(p);
  const schemaFields = createSchemaFieldsState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const isLoadFail = Actions.isLoadFail(p);
  const isLoadSuccess = Actions.isLoadSuccess(p);
  const isSubmit = Actions.isSubmit(p);
  const isSaveFail = Actions.isSaveFail(p);
  const isSaveSuccess = Actions.isSaveSuccess(p);
  const isLoadError = State.isLoadError(p);
  const isLoading = State.isLoading(p);
  const loadError = State.loadError(p);
  const saving = State.saving(p);
  const isSaving = State.isSaving(p);
  const ready = State.ready(p);
  const isReady = State.isReady(p);

  return (
    s: State.State<P>,
    a: Actions.Actions<P>,
  ): E.Either<Exits.Exits<P>, State.State<P>> => {
    if (isLoadFail(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        loadError({
          ...s.payload,
          error: a.payload,
        }),
      );
    }
    if (isLoadSuccess(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          fields: schemaFields.init({
            fields: a.payload.dataType.schema.fields,
            values: Obj.map(
              () => FormValue.initial(undefined),
              a.payload.dataType.schema.fields,
            ),
          }),
          customer: customerSearch.states.selected.create({
            item: {
              id: a.payload.order.customerId,
              title: a.payload.order.customerId,
            },
            items: [
              {
                id: a.payload.order.customerId,
                title: a.payload.order.customerId,
              },
            ],
            query: O.none,
          }),
          dataTypes: a.payload.dataTypes,
          removedItems: [],
          _initial: a.payload.order,
          items: pickingOrderItemsState.init({
            items: pipe(
              a.payload.order.items.map((v) => {
                const state =
                  pickingOrderItemsState.createPickingOrderItemState(v.id);
                const dataType =
                  a.payload.dataTypes.find((d) => d.id === v.dataTypeId) ??
                  a.payload.dataTypes.find((v) => v.isDefault) ??
                  a.payload.dataTypes[0];

                return [
                  v.id,
                  state.init({
                    fields: state.schemaFieldsState.init({
                      fields: dataType.schema.fields,
                      values: Obj.map((v) => FormValue.initial(v), v.fields),
                    }),
                    dataTypes: a.payload.dataTypes,
                    dataTypeId: v.dataTypeId,
                    sku: v.sku,
                    quantity: v.quantity,
                  }),
                ] satisfies [string, unknown];
              }),
              Obj.fromEntries,
            ),
            dataTypes: a.payload.dataTypes,
          }),
        }),
      );
    }
    if (customerSearch.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          customer: customerSearch.reducer(s.payload.customer, a),
        }),
      );
    }
    if (schemaFields.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          fields: schemaFields.reducer(s.payload.fields, a),
        }),
      );
    }
    if (pickingOrderItemsState.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: pipe(
            pickingOrderItemsState.reducer(s.payload.items, a),
            E.getOrElse(() => s.payload.items),
          ),
        }),
      );
    }
    if (isSaveFail(a)) {
      return isSaving(s) ? E.right(ready(s.payload)) : E.right(s);
    }
    if (isSaveSuccess(a)) {
      return isSaving(s)
        ? E.left(
            Exits.list(p)({
              id: a.payload.id,
              dataTypeId: a.payload.dataTypeId,
            }),
          )
        : E.right(s);
    }

    if (isSubmit(a)) {
      if (!isReady(s)) return E.right(s);

      const customer = s.payload.customer;
      const fields = schemaFields.reducer(
        s.payload.fields,
        schemaFields.actions.validate.create(),
      );
      const items = pipe(
        pickingOrderItemsState.reducer(
          s.payload.items,
          pickingOrderItemsState.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.items),
      );

      if (
        customerSearch.states.selected.is(customer) &&
        schemaFields.states.valid.is(fields) &&
        pickingOrderItemsState.states.valid.is(items)
      ) {
        return E.right(
          saving({
            ...s.payload,
            customer,
            fields,
            items,
          }),
        );
      }

      return E.right(
        ready({
          ...s.payload,
          customer,
          fields,
          items,
        }),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};
