import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Edit";
import { flow, pipe } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import * as O from "fp-ts/Option";
import * as FormValue from "types/src/FormValue";
import { HandlerInput } from "@Containers/Form/predefined/HandlerInput";
import { QuantityInput } from "@Containers/Form/predefined/QuantityInput";
import { Toggle } from "@Containers/Form/Toggle";
import { SchemaFields, SchemaFieldsProps } from "@Containers/SchemaFields";
import { useTranslation } from "i18n";
import { SearchCombobox, SelectorProps } from "@Containers/Form/SearchCombobox";
import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Repository } from "types/src/Repositories/Repository";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const { t } = useTranslation();
  const onSchemaChange: SchemaFieldsProps["onChange"] = (v) => {
    switch (v.type) {
      case "number":
        dispatch(
          Edit.schemaFieldsState.actions.setNumberValue.create({
            id: v.id,
            value: v.value as number,
          }),
        );
        break;
      case "text":
        dispatch(
          Edit.schemaFieldsState.actions.setTextValue.create({
            id: v.id,
            value: v.value as string,
          }),
        );
        break;
      default:
        silentUnreachableError(v.type);
    }
  };
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:ItemMovements:Edit:Loading":
        case "Ready:DataManager:ItemMovements:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:ItemMovements:Edit:Ready":
        case "Ready:DataManager:ItemMovements:Edit:Saving": {
          const currentSelector = flow(selector, (st) => st as typeof s);

          return {
            type: "ready",
            schemaSelector: flow(
              currentSelector,
              (s) => s.payload.schema.payload,
            ),
            itemSelector: flow(
              currentSelector,
              (s) => s.payload.item,
              (v): SelectorProps<InventoryItem> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.sku as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            fromSelector: flow(
              currentSelector,
              (s) => s.payload.from,
              (v): SelectorProps<Repository> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.name as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            toSelector: flow(
              currentSelector,
              (s) => s.payload.to,
              (v): SelectorProps<Repository> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.name as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            handlerSelector: flow(currentSelector, (s) => s.payload.handler),
            quantitySelector: flow(currentSelector, (s) => s.payload.quantity),
            executedSelector: flow(
              currentSelector,
              (s) => s.payload.isExecuted,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <Field>
            <Label>{t("Inventory Item")}</Label>
            <SearchCombobox
              getId={(v) => v.id}
              onChange={flow(O.map((v) => dispatch(Edit.setItem(v.id))))}
              onSearch={flow(Edit.searchItem, dispatch)}
              selector={r.itemSelector}
            />
          </Field>
          <Field>
            <Label>{t("Form repository")}</Label>
            <SearchCombobox
              getId={(v) => v.id}
              onChange={flow(O.map((v) => dispatch(Edit.setFrom(v.id))))}
              onSearch={flow(Edit.searchFrom, dispatch)}
              selector={r.fromSelector}
            />
          </Field>
          <Field>
            <Label>{t("To repository")}</Label>
            <SearchCombobox
              getId={(v) => v.id}
              onChange={flow(O.map((v) => dispatch(Edit.setTo(v.id))))}
              onSearch={flow(Edit.searchTo, dispatch)}
              selector={r.toSelector}
            />
          </Field>
          <HandlerInput
            value$={r.handlerSelector}
            onChange={flow(Edit.setHandler, dispatch)}
          />
          <QuantityInput
            value$={r.quantitySelector}
            onChange={flow(Edit.setQuantity, dispatch)}
          />
          <Field>
            <Toggle disabled value$={r.executedSelector}>
              {t("Is executed")}
            </Toggle>
          </Field>
          <SchemaFields selector={r.schemaSelector} onChange={onSchemaChange} />
        </FormWrapper>
      );
  }
}
