import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { JsonEditor } from "ui/components/JsonEditor";

export interface DataTypeJsonEditorProps {
  value$: Selector<string | undefined>;
  onChange: (value: string | undefined) => void;
}

export function DataTypeJsonEditor(p: DataTypeJsonEditorProps): ReactElement {
  const value = useSelector(p.value$, (a, b) => a === b);
  return (
    <JsonEditor
      content={value}
      onChange={p.onChange}
      schema={{
        $schema: "http://json-schema.org/draft-06/schema#",
        $id: "http://json-schema.org/draft-06/schema#",
        title: "DataType Schema definition",
        type: "object",
        required: [
          "$schema",
          "title",
          "description",
          "type",
          "properties",
          "required",
          // "additionalProperties",
        ],
        properties: {
          $schema: {
            type: "string",
            // format: "uri",
            enum: ["http://json-schema.org/draft-06/schema#"],
          },
          title: {
            type: "string",
          },
          additionalProperties: {
            type: "boolean",
            enum: [false],
          },
          description: {
            type: "string",
          },
          type: {
            type: "string",
            enum: ["object"],
          },
          properties: {
            type: "object",
            properties: {
              fields: {
                type: "object",
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["object"],
                  },
                  properties: {
                    type: "object",
                    additionalProperties: {
                      $ref: "#/definitions/field",
                    },
                  },
                  required: {
                    type: "array",
                    items: {
                      type: "string",
                    },
                  },
                },
              },
              fieldsSettings: {
                type: "object",
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["object"],
                  },
                  properties: {
                    type: "object",
                    additionalProperties: {
                      $ref: "#/definitions/fieldSettings",
                    },
                  },
                },
              },
            },
          },
          required: {
            type: "array",
            items: {
              type: "string",
              enum: ["fields"],
            },
          },
        },
        definitions: {
          field: {
            oneOf: [
              {
                type: "object",
                required: ["type"],
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["string"],
                  },
                  unique: {
                    type: "boolean",
                    default: false,
                  },
                  minLength: {
                    type: "integer",
                    minimum: 0,
                  },
                  maxLength: {
                    type: "integer",
                    minimum: 0,
                  },
                },
              },
              {
                type: "object",
                required: ["type"],
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["number"],
                  },
                  minimum: {
                    type: "number",
                  },
                  maximum: {
                    type: "number",
                  },
                },
              },
            ],
          },
          fieldSettings: {
            oneOf: [
              {
                type: "object",
                required: ["type", "properties"],
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["object"],
                  },
                  properties: {
                    type: "object",
                    additionalProperties: false,
                    required: [
                      "type",
                      "label",
                      "description",
                      "order",
                      "placeholder",
                    ],
                    properties: {
                      type: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                            enum: ["text"],
                          },
                        },
                      },
                      label: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      description: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      placeholder: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      order: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["number"],
                          },
                          default: {
                            type: "number",
                            minimum: 0,
                            maximum: 1,
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                type: "object",
                required: ["type", "properties"],
                additionalProperties: false,
                properties: {
                  type: {
                    type: "string",
                    enum: ["object"],
                  },
                  properties: {
                    type: "object",
                    additionalProperties: false,
                    required: [
                      "type",
                      "label",
                      "description",
                      "order",
                      "placeholder",
                    ],
                    properties: {
                      type: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                            enum: ["number"],
                          },
                        },
                      },
                      label: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      description: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      placeholder: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["string"],
                          },
                          default: {
                            type: "string",
                          },
                        },
                      },
                      order: {
                        type: "object",
                        additionalProperties: false,
                        required: ["type", "default"],
                        properties: {
                          type: {
                            type: "string",
                            enum: ["number"],
                          },
                          default: {
                            type: "number",
                            minimum: 0,
                            maximum: 1,
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      }}
    />
  );
}
