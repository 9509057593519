import { DataTypeId } from "types/src/DataType/DataType";
import { Sku } from "types/src/Sku";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import * as FormValue from "types/src/FormValue";
import { SchemaFieldsState, SchemaFieldsStatesMap } from "../utils";

export type State<P extends string> = Ready<P> | Valid<P>;

export const isState = <P extends string>(p: P) =>
  strictGuard((s: State<P>): s is State<P> => {
    if (isReady(p)(s) || isValid(p)(s)) return true;

    silentUnreachableError(s);
    return false;
  });

// region Ready
export interface ReadyPayload<P extends string> {
  dataTypeId: DataTypeId;
  fields: SchemaFieldsState<P>;
  dataTypes: Array<{ title: string; id: DataTypeId; schema: DataTypeSchema }>;
  sku: FormValue.Value<"required" | "invalid", Sku, string | undefined>;
  quantity: FormValue.Value<"required" | "invalid", number, number | undefined>;
}

export interface Ready<P extends string> {
  type: `${P}:Ready`;
  payload: ReadyPayload<P>;
}

export const ready =
  <P extends string>(p: P) =>
  (payload: Ready<P>["payload"]): Ready<P> => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  <P extends string>(p: P) =>
  (s: State<P>): s is Ready<P> =>
    s.type === `${p}:Ready`;
// endregion

// region Valid
export interface ValidPayload<P extends string> extends ReadyPayload<P> {
  sku: FormValue.Valid<Sku>;
  quantity: FormValue.Valid<number>;
  fields: SchemaFieldsStatesMap<P>["valid"];
}

export interface Valid<P extends string> {
  type: `${P}:Valid`;
  payload: ValidPayload<P>;
}

export const valid =
  <P extends string>(p: P) =>
  (payload: Valid<P>["payload"]): Valid<P> => ({
    type: `${p}:Valid`,
    payload,
  });

export const isValid =
  <P extends string>(p: P) =>
  (s: State<P>): s is Valid<P> =>
    s.type === `${p}:Valid`;
// endregion

export const init =
  <P extends string>(p: P) =>
  (payload: {
    dataTypeId: DataTypeId;
    fields: SchemaFieldsState<P>;
    dataTypes: Array<{ title: string; id: DataTypeId; schema: DataTypeSchema }>;
    sku: Sku | undefined;
    quantity: number | undefined;
  }): State<P> => {
    return ready(p)({
      sku: FormValue.initial(payload.sku),
      quantity: FormValue.initial(payload.quantity),
      dataTypeId: payload.dataTypeId,
      dataTypes: payload.dataTypes,
      fields: payload.fields,
    });
  };
