import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as DataTypes from "state-manager/states/Ready/states/DataManager/states/DataTypes";
import * as Customers from "state-manager/states/Ready/states/DataManager/states/Customers";
import * as Suppliers from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import * as Repositories from "state-manager/states/Ready/states/DataManager/states/Repositories";
import * as InventoryItems from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import * as ItemMovements from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import * as RepositoryMovements from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import * as CustomersListing from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import * as SuppliersListing from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import * as RepositoriesListing from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Listing";
import * as InventoryItemsListing from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import * as ItemMovementsListing from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import * as RepositoryMovementsListing from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import * as Stocks from "state-manager/states/Ready/states/DataManager/states/Stocks";
import * as Transactions from "state-manager/states/Ready/states/DataManager/states/Transactions";
import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow, pipe } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { useTranslation } from "i18n";
import * as ReadyActions from "state-manager/states/Ready";
import {
  SubNavItem,
  SubNavItemSubmenu,
  SubNavItemText,
} from "ui/layouts/PageLayout";
import * as O from "fp-ts/Option";
import { TranslatedStr } from "types/src/TranslatedStr";
import { isShallowEqual } from "utils/object";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { isOneOf } from "utils/isOneOf";

interface Item {
  type: string;
  title: TranslatedStr;
  action: ReadyActions.Goto;
  isActive: boolean;
}

export interface SidebarProps {
  selector: Selector<DataManager.State>;
  dispatch: (action: ReadyActions.Goto) => void;
}

export function Sidebar(p: SidebarProps): ReactElement {
  const { t } = useTranslation();
  const current = useSelector(
    flow(p.selector, (s) => {
      if (DataTypes.isState(s.payload.subState)) {
        return {
          subItems: [],
          type: "data-types",
        } as const;
      }

      if (Customers.isState(s.payload.subState)) {
        return {
          type: "customer",
          subItems: getDataTypesByEntity(
            "customer",
            ReadyActions.goToCustomersByDataType,
          )(s),
        } as const;
      }

      if (Suppliers.isState(s.payload.subState)) {
        return {
          type: "supplier",
          subItems: getDataTypesByEntity(
            "supplier",
            ReadyActions.goToSuppliersByDataType,
          )(s),
        } as const;
      }

      if (Repositories.isState(s.payload.subState)) {
        return {
          type: "repository",
          subItems: getDataTypesByEntity(
            "repository",
            ReadyActions.goToRepositoriesByDataType,
          )(s),
        } as const;
      }

      if (InventoryItems.isState(s.payload.subState)) {
        return {
          type: "item",
          subItems: getDataTypesByEntity(
            "item",
            ReadyActions.goToInventoryItemsByDataType,
          )(s),
        } as const;
      }

      if (ItemMovements.isState(s.payload.subState)) {
        return {
          type: "item-movements",
          subItems: getDataTypesByEntity(
            "movement",
            ReadyActions.goToItemMovementsByDataType,
          )(s),
        } as const;
      }

      if (RepositoryMovements.isState(s.payload.subState)) {
        return {
          type: "repository-movements",
          subItems: getDataTypesByEntity(
            "movement",
            ReadyActions.goToRepositoryMovementsByDataType,
          )(s),
        } as const;
      }

      if (Stocks.isState(s.payload.subState)) {
        return {
          type: "stocks",
        } as const;
      }

      if (Transactions.isState(s.payload.subState)) {
        return {
          type: "transactions",
        } as const;
      }

      if (DataManager.pickingOrderState.isState(s.payload.subState)) {
        return {
          type: "picking-orders",
          subItems: getDataTypesByEntity(
            "order",
            ReadyActions.goToPickingOrdersByDataType,
          )(s),
        } as const;
      }

      silentUnreachableError(s.payload.subState);
      return {
        subItems: [],
        type: "data-types",
      } as const;
    }),
    isShallowEqual,
  );

  return (
    <>
      <SubNavItem
        onClick={() => p.dispatch(ReadyActions.goToDataTypesListing())}
        isCurrent={current.type === "data-types"}
      >
        <SubNavItemText>{t("Data Types")}</SubNavItemText>
      </SubNavItem>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToCustomers())}
          isCurrent={current.type === "customer"}
        >
          <SubNavItemText>{t("Customers")}</SubNavItemText>
        </SubNavItem>
        {current.type === "customer" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToSuppliers())}
          isCurrent={current.type === "supplier"}
        >
          <SubNavItemText>{t("Suppliers")}</SubNavItemText>
        </SubNavItem>
        {current.type === "supplier" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToRepositories())}
          isCurrent={current.type === "repository"}
        >
          <SubNavItemText>{t("Repositories")}</SubNavItemText>
        </SubNavItem>
        {current.type === "repository" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToInventoryItems())}
          isCurrent={current.type === "item"}
        >
          <SubNavItemText>{t("Inventory Items")}</SubNavItemText>
        </SubNavItem>
        {current.type === "item" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToItemMovements())}
          isCurrent={current.type === "item-movements"}
        >
          <SubNavItemText>{t("Item Movements")}</SubNavItemText>
        </SubNavItem>
        {current.type === "item-movements" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToRepositoryMovements())}
          isCurrent={current.type === "repository-movements"}
        >
          <SubNavItemText>{t("Repositories Movements")}</SubNavItemText>
        </SubNavItem>
        {current.type === "repository-movements" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToStocks())}
          isCurrent={current.type === "stocks"}
        >
          <SubNavItemText>{t("Stocks")}</SubNavItemText>
        </SubNavItem>
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToTransactions())}
          isCurrent={current.type === "transactions"}
        >
          <SubNavItemText>{t("Transactions")}</SubNavItemText>
        </SubNavItem>
      </div>
      <div>
        <SubNavItem
          onClick={() => p.dispatch(ReadyActions.goToPickingOrders())}
          isCurrent={current.type === "picking-orders"}
        >
          <SubNavItemText>{t("Picking Orders")}</SubNavItemText>
        </SubNavItem>
        {current.type === "picking-orders" &&
          current.subItems.map((item) => (
            <SubNavItemSubmenu isVisible>
              <SubNavItem
                onClick={() => p.dispatch(item.action)}
                isCurrent={item.isActive}
              >
                <SubNavItemText>{item.title || t("No title")}</SubNavItemText>
              </SubNavItem>
            </SubNavItemSubmenu>
          ))}
      </div>
    </>
  );
}

function getDataTypesByEntity(
  entity: DataTypeEntity,
  action: (id: DataTypeId) => ReadyActions.Goto,
): (s: DataManager.State) => Item[] {
  return flow(
    O.of<DataManager.State>,
    O.filter(DataManager.isReady),
    O.map((s) => {
      return s.payload.dataTypes
        .filter((d) => d.entity === entity)
        .map(
          (d): Item => ({
            type: `${entity}:data-type:${d.id}`,
            title: d.name,
            action: action(d.id),
            isActive: pipe(
              s.payload.subState,
              O.fromPredicate(
                isOneOf([
                  CustomersListing.isState,
                  SuppliersListing.isState,
                  RepositoriesListing.isState,
                  InventoryItemsListing.isState,
                  ItemMovementsListing.isState,
                  RepositoryMovementsListing.isState,
                  DataManager.pickingOrderState.subStates.listing.isState,
                ]),
              ),
              O.map((s) => s.payload.id === d.id),
              O.getOrElse<boolean>(() => false),
            ),
          }),
        );
    }),
    O.getOrElse<Item[]>(() => []),
  );
}
