import { RootState, useDispatch, useSelector, useStore } from "state-manager";
import { Field } from "types/src/DataType/Field";
import { FieldType } from "types/src/DataType/FieldType";
import { Field as UIField } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { useTranslation } from "i18n";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Input } from "@Containers/Form/Input";
import { ReactElement, useMemo } from "react";
import {
  setConfig,
  setDesc,
  setLabel,
  setRequired,
  setUnique,
} from "state-manager/generic-states/Schema";
import { Toggle } from "@Containers/Form/Toggle";
import { Textarea } from "@Containers/Form/Textarea";
import { Number } from "@Containers/Form/Number";
import { flow } from "fp-ts/function";

export interface SettingsProps {
  selector: (state: RootState) => Field<FieldType>;
}

export function Settings({ selector }: SettingsProps) {
  const id = useSelector(flow(selector, (s) => s.id));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <FormWrapper>
      <UIField>
        <Label>{t("Label")}</Label>
        <Input
          value$={flow(selector, (v) => v.label)}
          onChange={(value) => dispatch(setLabel({ id, value }))}
        />
      </UIField>
      <UIField>
        <Label>{t("Description")}</Label>
        <Textarea
          value$={flow(selector, (v) => v.description)}
          onChange={(value) => dispatch(setDesc({ id, value }))}
        />
      </UIField>
      <UIField>
        <Toggle
          value$={flow(selector, (v) => v.required)}
          onChange={(value) => dispatch(setRequired({ id, value }))}
        >
          {t("Required")}
        </Toggle>
      </UIField>
      <UIField>
        <Toggle
          value$={flow(selector, (v) => v.unique)}
          onChange={(value) => dispatch(setUnique({ id, value }))}
        >
          {t("Unique")}
        </Toggle>
      </UIField>
      <FieldSettings selector={selector} />
    </FormWrapper>
  );
}

function FieldSettings(p: {
  selector: (state: RootState) => Field<FieldType>;
}): ReactElement {
  const type = useSelector(flow(p.selector, (s) => s.type));

  switch (type) {
    case "text": {
      const s = flow(p.selector, (v) => v as Field<"text">);
      return <TextSettings selector={s} />;
    }
    case "number": {
      const s = flow(p.selector, (v) => v as Field<"number">);
      return <NumberSettings selector={s} />;
    }
  }
}

interface TextSettingsProps {
  selector: (state: RootState) => Field<"text">;
}
function TextSettings({ selector }: TextSettingsProps) {
  const { t } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();
  const minLengthSelector = useMemo(
    () => flow(selector, (v) => v.config.minLength),
    [selector],
  );
  const maxLengthSelector = useMemo(
    () => flow(selector, (v) => v.config.maxLength),
    [selector],
  );
  const placeholderSelector = useMemo(
    () => flow(selector, (v) => v.config.placeholder ?? ""),
    [selector],
  );

  return (
    <>
      <UIField>
        <Label>{t("Min length")}</Label>
        <Number
          value$={minLengthSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: { ...field.config, minLength: v },
              }),
            );
          }}
        />
      </UIField>
      <UIField>
        <Label>{t("Max length")}</Label>
        <Number
          value$={maxLengthSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: { ...field.config, maxLength: v },
              }),
            );
          }}
        />
      </UIField>
      <UIField>
        <Label>{t("Placeholder")}</Label>
        <Input
          value$={placeholderSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: { ...field.config, placeholder: v },
              }),
            );
          }}
        />
      </UIField>
    </>
  );
}

interface NumberSettingsProps {
  selector: (state: RootState) => Field<"number">;
}
function NumberSettings({ selector }: NumberSettingsProps) {
  const { t } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();
  const minLengthSelector = useMemo(
    () => flow(selector, (v) => v.config.min),
    [selector],
  );
  const maxLengthSelector = useMemo(
    () => flow(selector, (v) => v.config.max),
    [selector],
  );
  const placeholderSelector = useMemo(
    () => flow(selector, (v) => v.config.placeholder ?? ""),
    [selector],
  );

  return (
    <>
      <UIField>
        <Label>{t("Min")}</Label>
        <Number
          value$={minLengthSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: { ...field.config, min: v },
              }),
            );
          }}
        />
      </UIField>
      <UIField>
        <Label>{t("Max")}</Label>
        <Number
          value$={maxLengthSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: {
                  ...field.config,
                  max: v,
                },
              }),
            );
          }}
        />
      </UIField>
      <UIField>
        <Label>{t("Placeholder")}</Label>
        <Input
          value$={placeholderSelector}
          onChange={(v) => {
            const field = selector(store.getState());
            dispatch(
              setConfig({
                id: field.id,
                value: { ...field.config, placeholder: v },
              }),
            );
          }}
        />
      </UIField>
    </>
  );
}
