import { flow } from "fp-ts/function";
import { SchemaFields } from "@Containers/SchemaFields";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { ReactElement } from "react";
import * as O from "fp-ts/Option";
import {
  StateConstructor,
  Actions,
  State,
} from "state-manager/generic-states/PickingOrderItem";
import { Selector, useSelector } from "state-manager";
import { SkuInput } from "@Containers/Form/predefined/SkuInput";
import { QuantityInput } from "@Containers/Form/predefined/QuantityInput";
import { silentUnreachableError } from "utils/exceptions";
import { Combobox } from "@Containers/Form/Combobox";
import { DataTypeId } from "types/src/DataType/DataType";
import * as Obj from "utils/object";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Field, Label } from "ui/components/Combobox";
import { useTranslation } from "i18n";

export interface ContentProps<P extends string> {
  value$: Selector<State<P>>;
  dispatch: (a: Actions<P>) => void;
  constructor: StateConstructor<P>;
}

export function Form<P extends string>(p: ContentProps<P>): ReactElement {
  return (
    <FormWrapper>
      <SkuInput
        value$={flow(p.value$, (v) => v.payload.sku)}
        onChange={flow(p.constructor.actions.setSku.create, p.dispatch)}
      />
      <QuantityInput
        value$={flow(p.value$, (v) => v.payload.quantity)}
        onChange={flow(
          O.toUndefined,
          p.constructor.actions.setQuantity.create,
          p.dispatch,
        )}
      />
      <DataTypeSelect
        value$={flow(p.value$, (v) => v.payload.dataTypeId)}
        dataTypes$={flow(p.value$, (v) => v.payload.dataTypes)}
        onChange={flow(p.constructor.actions.setDataType.create, p.dispatch)}
      />
      <SchemaFields
        selector={flow(p.value$, (v) => v.payload.fields.payload)}
        onChange={(v) => {
          switch (v.type) {
            case "number": {
              return p.dispatch(
                p.constructor.schemaFieldsState.actions.setNumberValue.create({
                  id: v.id,
                  value: v.value as number,
                }),
              );
            }
            case "text": {
              return p.dispatch(
                p.constructor.schemaFieldsState.actions.setTextValue.create({
                  id: v.id,
                  value: v.value as string,
                }),
              );
            }
            default: {
              silentUnreachableError(v.type);
            }
          }
        }}
      />
    </FormWrapper>
  );
}

function DataTypeSelect(p: {
  value$: Selector<DataTypeId>;
  dataTypes$: Selector<Array<{ id: DataTypeId; title: string }>>;
  onChange: (id: DataTypeId) => void;
}) {
  const { t } = useTranslation();
  const options = useSelector(
    flow(p.dataTypes$, (vs) =>
      vs.map((v) => ({ value: v.id, label: v.title as TranslatedStr })),
    ),
    Obj.isDeepEqual,
  );

  return (
    <Field>
      <Label>{t("Data Type")}</Label>
      <Combobox value$={p.value$} onChange={p.onChange} options={options} />
    </Field>
  );
}
