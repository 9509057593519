import { gql } from "@apollo/client";
import type {
  FieldId,
  FieldType,
  FieldValue,
} from "types/src/DataType/FieldType";
import type { DataTypeId } from "types/src/DataType/DataType";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isT } from "fp-utilities";
import type {
  PickingOrderId,
  PickingOrder,
} from "types/src/PickingOrder/PickingOrder";
import { CustomerId } from "types/src/Customers/Customer";
import {
  PickingOrderItemCreateWithoutOrder,
  PickingOrderItemId,
} from "types/src/PickingOrder/PickingOrderItem";
import { omitEmpties } from "utils/value";
import type {
  CreatePickingOrderMutation,
  CreatePickingOrderMutationVariables,
  PickingOrderFragmentFragment,
  UpdatePickingOrderMutation,
  UpdatePickingOrderMutationVariables,
} from "../../generated/graphql";
import { Client, notFoundError } from "../../index";
import type { DsError } from "../../type/DsError";
import type { QueryResponse } from "../../type/QueryResponse";
import { pickingOrderFragment } from "../../fragments/PickingOrder";
import { pickingOrderFragmentToPickingOrder } from "../../transformers/PickingOrder";
import { pickingOrderItemCreateWithoutOrderToCreatePickingOrderItemsInput } from "../../transformers/PickingOrderItem";

export const createPickingOrder = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: Record<FieldId, FieldValue<FieldType>>;
    customerId: CustomerId;
    orderItems: PickingOrderItemCreateWithoutOrder[];
  },
): Promise<QueryResponse<PickingOrder>> => {
  const mutation = gql`
    ${pickingOrderFragment}
    mutation CreatePickingOrder($input: CreatePickingOrderWithItemsInput!) {
      createPickingOrder(input: $input) {
        ...PickingOrderFragment
      }
    }
  `;

  return client
    .mutate<CreatePickingOrderMutation, CreatePickingOrderMutationVariables>({
      mutation,
      variables: {
        input: {
          data: omitEmpties({
            fields: vars.fields,
          }),
          dataTypeID: vars.dataTypeId,
          customerID: vars.customerId,
          orderItems: vars.orderItems.map(
            pickingOrderItemCreateWithoutOrderToCreatePickingOrderItemsInput,
          ),
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createPickingOrder),
        E.filterOrElseW(isT, notFoundError),
        E.map(pickingOrderFragmentToPickingOrder),
      ),
    );
};

export const updatePickingOrder = (
  client: Client,
  vars: {
    id: PickingOrderId;
    dataTypeId: DataTypeId;
    fields: Record<FieldId, FieldValue<FieldType>>;
    addedItems: PickingOrderItemId[];
    removedItems: PickingOrderItemId[];
  },
): Promise<QueryResponse<PickingOrder>> => {
  const mutation = gql`
    ${pickingOrderFragment}
    mutation UpdatePickingOrder($id: ID!, $input: UpdatePickingOrderInput!) {
      updatePickingOrder(id: $id, input: $input) {
        ...PickingOrderFragment
      }
    }
  `;

  return client
    .mutate<UpdatePickingOrderMutation, UpdatePickingOrderMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          addOrderItemIDs: omitEmpties(vars.addedItems),
          removeOrderItemIDs: omitEmpties(vars.removedItems),
          data: omitEmpties({
            fields: vars.fields,
          }),
        },
      },
    })
    .then(
      flow(
        E.map((node) =>
          pickingOrderFragmentToPickingOrder(
            node.updatePickingOrder as PickingOrderFragmentFragment,
          ),
        ),
      ),
    );
};

export const deletePickingOrders = (
  client: Client,
  ids: PickingOrderId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_picking_order_id: deletePickingOrder(id: "[PICKING_ORDER_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[PICKING_ORDER_ID]", id)
      .replace("data_picking_order_id", `data_picking_order_${i}`),
  );
  const query = `
        mutation DeletePickingOrders {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};
